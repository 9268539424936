/* line 13, scss/80-themes/Saunders/70-modules/freeformhtml/module.scss */
.m-freeformhtml {
	width: 100%;
}

/* line 17, scss/80-themes/Saunders/70-modules/freeformhtml/module.scss */
.freeformhtml__vidwrap {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
	height: 0;
	box-sizing: border-box;
}

/* line 24, scss/80-themes/Saunders/70-modules/freeformhtml/module.scss */
.freeformhtml__vidwrap object, .freeformhtml__vidwrap iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/*# sourceMappingURL=../../../../../true */